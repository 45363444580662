import React from 'react';
import {
    withStyles,
} from '@material-ui/core/styles';
import {
    AppBar,
    Button,
    CircularProgress,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tabs,
    TextField,
    Typography,
} from '@material-ui/core';
// import PropTypes from 'prop-types';
import { DatePicker, DateTimePicker } from 'material-ui-pickers';
import AuthorisedIcon from '@material-ui/icons/CheckCircleOutline';
import DeclinedIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import UnassignIcon from '@material-ui/icons/AssignmentReturn';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import {
    Link,
    DirectLink,
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from 'react-scroll';
import { withSnackbar } from 'notistack';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Error';
import CrossCircleIcon from '@material-ui/icons/Cancel';
import BulletIcon from '@material-ui/icons/FiberManualRecord';
import AddIcon from '@material-ui/icons/Add';
import SwapIcon from '@material-ui/icons/SwapHoriz';
import {
    get,
    isEmpty,
    sortBy,
} from 'lodash';
import moment from 'moment-timezone';
import classNames from 'classnames';
import color from '@material-ui/core/colors/amber';
import HybridTextField from '../../hybridTextField';
import titalise from '../../../utilities/titalise';
import Tile from '../../../containers/tile';
import ContactHistory from '../contactHistory';
import capitalisedSnakeCaseToTitleCase from '../../../utilities/capitalisedSnakeCaseToTitleCase';

const currentDateTime = moment.tz(moment(), 'Europe/London');
// import ContactSaleBadges from '../contactSaleBadges';

const styles = (theme) => ({
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 7,
        textAlign: 'left',
        width: '100%',
    },
    tileBottomDivider: {
        marginTop: theme.spacing.unit * 3,
        width: '100%',
    },
    bottomBarContainer: {
        textAlign: 'right',
        width: '100%',
    },
    textButton: {
        '&:first-of-type': {
            marginRight: 16,
        },
        marginBottom: 0,
        marginTop: theme.spacing.unit * 1,
        minWidth: 204,
    },
    textButtonContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    dialogActions: {
        marginRight: theme.spacing.unit * 2.5,
    },
    hybridTextIconLeft: {
        fontSize: 16,
        marginRight: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    hybridTextIconRight: {
        fontSize: 16,
        marginLeft: theme.spacing.unit * 1,
        transform: 'translate(0%, 13%)',
    },
    cancelledIcon: {
        color: '#d7d7d7',
        // color: '#f1180b',
    },
    warmIcon: {
        color: '#E7C251',
    },
    coldIcon: {
        color: '#BBFFFF',
    },
    notSetIcon: {
        color: '#d7d7d7',
    },
    activeIcon: {
        color: '#4caf50',
    },
    warningIcon: {
        color: '#fac518',
    },
    alert: {
        color: '#f1180b',
    },
    alertContainer: {
        /* text-align: left; */
        border: '1px solid',
        borderRadius: 4,
        fontWeight: 400,
        marginBottom: theme.spacing.unit * 4,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesAlertContainer: {
        /* text-align: left; */
        backgroundColor: '#f1180b',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    deliveriesOKContainer: {
        /* text-align: left; */
        backgroundColor: '#4caf50',
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        padding: theme.spacing.unit * 2,
        width: '100%',
    },
    bumpBottom4U: {
        marginBottom: theme.spacing.unit * 4,
    },
    bumpBottom3U: {
        marginBottom: theme.spacing.unit * 3,
    },
    bumpBottom2U: {
        marginBottom: theme.spacing.unit * 2,
    },
    bumpBottom1U: {
        marginBottom: theme.spacing.unit * 1,
    },
    warning: {
        color: '#ff9800',
    },

    tabsPaper: {
        alignItems: 'center',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        paddingBottom: 24,
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 32,
        width: '100%',
    },
    tabsAppBar: {
        backgroundColor: theme.palette.primary.lighter,
    },
    tab: {
        color: 'white',
        fontSize: 15,
        fontWeight: 500,
        // height: 64,
        minHeight: 38,
    },
    tabs: {
        // height: 64,
        minHeight: 38,
        padding: 0,
    },
    tabButton: {
        backgroundColor: 'red',
        height: 64,
        minHeight: 38,
        padding: 0,
    },
    inputLabel: {
        backgroundColor: '#fff',
        marginLeft: -5,
        paddingLeft: 8,
        paddingRight: 8,
    },
    formInstructions: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },
    statusSelect: {
        width: 200,
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        width: '100%',
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 5,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogActionsDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    tileDivider: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
    radioDescription: {
        marginLeft: 34,
        marginRight: theme.spacing.unit * 3,
        marginTop: -8,
    },
    issuerRadioDescription: {
        color: theme.palette.text.secondary,
        margin: 0,
    },
    disabledText: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    radioButtonsContainer: {
        marginLeft: theme.spacing.unit * 3,
    },
    menuItemText: {
        color: theme.palette.text.primary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    menuItemSubtext: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
    },
    selectMultiline: {
        height: 75,
    },
    convertDialogContent: {
        paddingBottom: 0,
        width: 540,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
        cursor: 'pointer',
    },
    tableHead: {
        fontSize: 14,
        fontWeight: 'bold',
        height: 48,
    },
    tableHeadRow: {
        height: 48,
    },
    tableIndex: {
        paddingTop: theme.spacing.unit,
        verticalAlign: 'top',
    },
    marginTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    inputAdornment: {
        color: theme.palette.text.secondary,
        fontSize: 16,
        marginTop: 1,
    },
    sectionContainer: {
        borderBottom: '1px solid #eaeaea',
        marginBottom: theme.spacing.unit * 3,
        paddingBottom: theme.spacing.unit * 1,
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        textAlign: 'center',
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    declinedStatusIcon: {
        color: red[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    authorisedStatusIcon: {
        color: green[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    ErrorStatusIcon: {
        color: amber[400],
        fontSize: 100,
        margin: theme.spacing.unit * 2,
    },
    cardRadio: {
        paddingTop: '0px',
    },
    issuerRadioLabel: {
        marginTop: theme.spacing.unit * 1,
    },
    emptyContent: {
        padding: 32,
    },
    textField: {
        width: 260,
    },
    bumpTop1U: {
        marginTop: theme.spacing.unit * 1,
    },
    bumpTop2U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3U: {
        marginTop: theme.spacing.unit * 2,
    },
    bumpTop3: {
        marginTop: theme.spacing.unit * 3,
    },
    bumpLeft3U: {
        marginLeft: theme.spacing.unit * 3,
    },
    bulletIcon: {
        fontSize: 11,
        marginLeft: theme.spacing.unit * 2,
        marginRight: theme.spacing.unit * 1.5,
    },
    deliveryAlertList: {
        fontWeight: 400,
        marginTop: theme.spacing.unit * 1,
    },
    subTile: {
        marginBottom: theme.spacing.unit * 4,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    gaugeContainer: {
        width: 100,
        // marginLeft: 'auto',
        // marginRight: 'auto',
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    pdfDialogContent: {
        minWidth: 300,
        minHeight: 300,
    },
    signatureImage: {
        border: '1px solid darkgrey',
        boxShadow: '5px 5px 5px 1px #ccc',
        borderRadius: 5,
        maxWidth: 680,
    },
    dialogActionsRight: {
        textAlign: 'right',
    },
    dialogDownloadButton: {
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '8px',
        fontSize: '0.875rem',
        minWidth: '64px',
        boxSizing: 'border-box',
        minHeight: '36px',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '500',
        lineHeight: '1.5',
        borderRadius: '4px',
        letterSpacing: '0.02857em',
        textTransform: 'uppercase',
        border: '0',
        margin: '0',
        cursor: 'pointer',
        display: 'inline-flex',
        outline: 'none',
        position: 'relative',
        alignItems: 'center',
        userSelect: 'none',
        verticalAlign: 'middle',
        justifyContent: 'center',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        webkitAppearance: 'none',
        webkitTapHighlightColor: 'transparent',
        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    signatureDialogContent: {
        textAlign: 'center',
    },
    checkboxIndent: {
        marginLeft: theme.spacing.unit * 3,
    },
    buildSendContractDialog: {
        maxWidth: 400,
    },
    emailStatementDialog: {
        maxWidth: 400,
    },
    engineerScheduleDialogContent: {
        textAlign: 'center',
    },
    updatedDateTimes: {
        marginTop: theme.spacing.unit * 4,
    },
    pleaseWait: {
        marginTop: 10,
        fontWeight: 700,
        marginLeft: 'auto',
        marginRight: 'auto',
        opacity: '.5',
        marginBottom: 72,
    },
    pleaseWaitSpinnerContainer: {
        marginTop: 24,
        marginBottom: 24,
    },
    resultIcon: {
        fontSize: 94,
    },
    error: {
        color: '#ff0000',
    },
    warning: {
        color: '#f9aa33',
    },
    success: {
        color: '#4caf50',
    },
    stateContainer: {
        marginBottom: 32,
        marginTop: 32,
        textAlign: 'center',
    },
    resultLabel: {
        marginTop: 10,
        fontWeight: 700,
    },
    resultprogress: {
        opacity: '.5',
        width: '94px !important',
        height: '94px !important',
    },
    dialogContentAlertText: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
    },
    dialogContentTitle: {
        color: '#c80000',
        fontSize: 16,
        opacity: 0.54,
        fontWeight: 700,
    },
    blackTableIcons: {
        color: '#000000',
        fontSize: 22,
        opacity: 0.6,
    },
    customTableCell: {
        verticalAlign: 'middle',
        marginTop: 16,
    },
    editItemAgreementTableRow: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    editAgreementItemTable: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 2,
        border: '1px solid #e0e0e0',
    },
    editAgreementItemList: {
        marginLeft: theme.spacing.unit * 4,
        marginTop: theme.spacing.unit * 2,
        color: '#c80000',
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 4,
    },
    cardIssuer: {
        color: '#a3a3a3',
    },
    leadNoteContainer: {
        borderStyle: 'solid',
        borderWidth: 1,
        padding: '16px 16px 0px 16px',
        borderRadius: 8,
        borderColor: '#7e919d30',
        backgroundColor: 'aliceblue',
        marginBottom: 16,
    },
    radioButtons: {
        marginLeft: theme.spacing.unit * 3,
    },
    radioButtonCold: {
        color: 'cyan',
        '&$checked': {
            color: 'cyan',
        },
    },
    radioButtonWarm: {
        color: '#ffbf00 !important',
        '&$checked': {
            color: '#ffbf00',
        },
    },
    radioButtonHot: {
        color: 'red',
        '&$checked': {
            color: 'red',
        },
    },
    checked: {},
});

const currentDate = moment.tz(moment(), 'Europe/London');

class ContactLeads extends React.Component {
    state = {
        addLeadNoteData: {
            note: '',
        },
        disableAddLeadNoteSaveButton: false,
        disableAuthoriseLeadButton: false,
        disableCallbackSubmitButton: false,
        disableCancelCallbackButton: false,
        disableDeclineLeadButton: false,
        disableEditLeadSubmitButton: false,
        disableNoResponseRequiredButton: false,
        disableRegisterCallButton: false,
        disableSendLeadLinkButton: false,
        disableLeadToSlowTrackButton: false,
        disableSendSelfMeasureInviteButton: false,
        disableSendSmsButton: false,
        editLeadData: {},
        callbackData: {},
        currentLeadTab: 0,
        sendSmsData: {
            content: '',
            sendAsAgent: '',
        },
        showSendLeadLinkDialog: false,
        showLeadToSlowTrackDialog: false,
        showAddLeadNoteDialog: false,
        showSendSelfMeasureInviteDialog: false,
        showAuthoriseLeadDialog: false,
        showEditLeadDialog: false,
        showCallbackDialog: false,
        showDeclineLeadDialog: false,
        showSendSmsDialog: false,
    };

    async componentDidMount() {
        // await this.changeLeadTab();
    }

    componentDidUpdate(prevProps) {
        // if (this.props.requestedLeadTab !== prevProps.requestedLeadTab) {
        //     this.changeLeadTab();
        // }
    }

    changeLeadTab = async () => {
        // if (this.props.requestedLeadTab >= 0) {
        //     await this.scrollToLeadsTop();
        //     this.setState({
        //         currentLeadTab: this.props.requestedLeadTab,
        //     });
        // }
    };

    // handleCloseExchangeAgreementItemDialogClick = () => {
    //     this.setState((state) => ({
    //         showExchangeAgreementItemDialog: false,
    //     }));
    // };

    scrollToLeadsTop = () => {
        scroller.scrollTo('leadsTop', {
            delay: 100,
            duration: 800,
            offset: -90,
            smooth: 'easeInOutQuart',
        });
    };

    // capitaliseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    handleAddLeadNoteClick = () => {
        this.setState({ showAddLeadNoteDialog: true });
    };

    handleSendSelfMeasureInviteClick = () => {
        this.setState({ showSendSelfMeasureInviteDialog: true });
    };

    handleSendLeadLinkClick = () => {
        this.setState({ showSendLeadLinkDialog: true });
    };

    handleLeadToSlowTrackClick = () => {
        this.setState({ showLeadToSlowTrackDialog: true });
    };

    handleAuthoriseLeadClick = () => {
        this.setState({ showAuthoriseLeadDialog: true });
    };

    handleDeclineLeadClick = () => {
        this.setState({ showDeclineLeadDialog: true });
    };

    handleAddLeadNoteCloseDialogClick = () => {
        this.setState({ showAddLeadNoteDialog: false });
    };

    handleSendSelfMeasureInviteCloseDialogClick = () => {
        this.setState({ showSendSelfMeasureInviteDialog: false });
    };

    handleSendLeadLinkCloseDialogClick = () => {
        this.setState({ showSendLeadLinkDialog: false });
    };

    handleLeadToSlowTrackCloseDialogClick = () => {
        this.setState({ showLeadToSlowTrackDialog: false });
    };

    handleAuthoriseLeadCloseDialogClick = () => {
        this.setState({ showAuthoriseLeadDialog: false });
    };

    handleDeclineLeadCloseDialogClick = () => {
        this.setState({ showDeclineLeadDialog: false });
    };

    handleCloseEditLeadDialogClick = () => {
        this.setState({ showEditLeadDialog: false });
    };

    handleCloseCallbackDialogClick = () => {
        this.setState({ showCallbackDialog: false });
    };

    handleEditLeadClick = () => {
        const leadState = this.props.contact.leads[this.state.currentLeadTab].state;
        const { leadTemperature, assignedToContactCode } = this.props.contact.leads[this.state.currentLeadTab];
        this.setState({
            editLeadData: {
                assignedToContactCode,
                leadTemperature,
                state: leadState,
            },
            showEditLeadDialog: true,
        });
    };

    handleCallbackClick = () => {
        const { callbackDateTime } = this.props.contact.leads[this.state.currentLeadTab];
        this.setState({
            callbackData: {
                callbackDateTime,
            },
            showCallbackDialog: true,
        });
    };

    handleCloseSendSmsDialogClick = () => {
        this.setState({ showSendSmsDialog: false });
    };

    handleSendSmsClick = () => {
        const leadState = this.props.contact.leads[this.state.currentLeadTab].state;
        this.setState({
            sendSmsData: {
                content: null,
                sendAsAgent: this.props.user.contact.contactAgentCode ? 1 : 0,
            },
            showSendSmsDialog: true,
        });
    };

    handleAuthoriseLead = () => {
        this.setState({
            disableAuthoriseLeadButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        const params = {
            state: 'NEW',
        };
        this.props.authoriseLead(leadCode, params).then(() => {
            this.setState({
                disableAuthoriseLeadButton: false,
                showAuthoriseLeadDialog: false,
            });
            this.props.enqueueSnackbar('Lead Authorised', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAuthoriseLeadButton: false,
            });
            this.props.enqueueSnackbar('Authorise Lead failed', { variant: 'error' });
        });
    };

    handleDeclineLead = () => {
        this.setState({
            disableDeclineLeadButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        const params = {
            state: 'NEW',
        };
        this.props.declineLead(leadCode, params).then(() => {
            this.setState({
                disableDeclineLeadButton: false,
                showDeclineLeadDialog: false,
            });
            this.props.enqueueSnackbar('Lead Declined', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableDeclineLeadButton: false,
            });
            this.props.enqueueSnackbar('Decline Lead failed', { variant: 'error' });
        });
    };

    handleLeadChange = (event, newValue) => {
        this.setState({ currentLeadTab: newValue });
        // this.props.history.push(`/contact/${this.props.contact.code}?leadCode=${this.props.contact.leads[newValue].code}`);
    };

    updateAddLeadNoteData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            addLeadNoteData: {
                ...state.addLeadNoteData,
                [name]: formattedValue,
            },
        }));
    };

    handleAddLeadNote = () => {
        this.setState({
            disableAddLeadNoteSaveButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        const params = {
            ...this.state.addLeadNoteData,
        };
        this.props.addLeadNote(leadCode, params).then(() => {
            this.setState({
                disableAddLeadNoteSaveButton: false,
                showAddLeadNoteDialog: false,
            });
            this.props.enqueueSnackbar('New Lead Note Saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableAddLeadNoteSaveButton: false,
            });
            this.props.enqueueSnackbar('Save New Lead Note Failed', { variant: 'error' });
        });
    };

    handleSendSelfMeasureInvite = () => {
        this.setState({
            disableSendSelfMeasureInviteButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        this.props.sendSelfMeasureInvite(leadCode).then(() => {
            this.setState({
                disableSendSelfMeasureInviteButton: false,
                showSendSelfMeasureInviteDialog: false,
            });
            this.props.enqueueSnackbar('Self-Measure Invite Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableSendSelfMeasureInviteButton: false,
            });
            this.props.enqueueSnackbar('Send Self-Measure Invite Failed', { variant: 'error' });
        });
    };

    handleSendLeadLink = () => {
        this.setState({
            disableSendLeadLinkButton: true,
        });
        this.props.sendLeadLink().then(() => {
            this.setState({
                disableSendLeadLinkButton: false,
                showSendLeadLinkDialog: false,
            });
            this.props.enqueueSnackbar('Lead Link Sent', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableSendLeadLinkButton: false,
            });
            this.props.enqueueSnackbar('Send LeadLink Failed', { variant: 'error' });
        });
    };

    handleLeadToSlowTrack = () => {
        this.setState({
            disableLeadToSlowTrackButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        this.props.leadToSlowTrack(leadCode).then(() => {
            this.setState({
                disableLeadToSlowTrackButton: false,
                showLeadToSlowTrackDialog: false,
            });
            this.props.enqueueSnackbar('Lead Move To Slow Track', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableLeadToSlowTrackButton: false,
            });
            this.props.enqueueSnackbar('Move Lead To Slow Track Failed', { variant: 'error' });
        });
    };

    handleRegisterCall = () => {
        this.setState({
            disableRegisterCallButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        this.props.registerCall(leadCode).then(() => {
            this.setState({
                disableRegisterCallButton: false,
            });
            this.props.enqueueSnackbar('Call Registered', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableRegisterCallButton: false,
            });
            this.props.enqueueSnackbar('Register Call Failed', { variant: 'error' });
        });
    };

    updateEditLeadData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            editLeadData: {
                ...state.editLeadData,
                [name]: formattedValue,
            },
        }));
    };

    updateSendSmsData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            sendSmsData: {
                ...state.sendSmsData,
                [name]: formattedValue,
            },
        }));
    };

    handleEditLead = () => {
        this.setState({
            disableEditLeadSubmitButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        const params = this.state.editLeadData;
        this.props.editLead(leadCode, params).then(() => {
            this.setState({
                disableEditLeadSubmitButton: false,
                showEditLeadDialog: false,
            });
            this.props.enqueueSnackbar('Lead Updated', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableEditLeadSubmitButton: false,
            });
            this.props.enqueueSnackbar('Update Lead Failed', { variant: 'error' });
        });
    };

    handleCallback = () => {
        this.setState({
            disableCallbackSubmitButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        const params = this.state.callbackData;
        this.props.editLead(leadCode, params).then(() => {
            this.setState({
                disableCallbackSubmitButton: false,
                showCallbackDialog: false,
            });
            this.props.enqueueSnackbar('Callback Set', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableCallbackSubmitButton: false,
            });
            this.props.enqueueSnackbar('Set Callback Failed', { variant: 'error' });
        });
    };

    handleSendSms = (event) => {
        event.preventDefault();
        this.setState({
            disableSendSmsButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        const payload = {
            ...this.state.sendSmsData,
            entryType: 'LEAD',
            entryTypeReferenceCode: `L${leadCode}`,
            leadCode,
            sendAsAgent: this.state.sendSmsData.sendAsAgent === 1,
            smsMessage: this.state.sendSmsData.content,
        };
        this.props.sendSMS(payload).then(() => {
            this.setState({
                disableSendSmsButton: false,
                showSendSmsDialog: false,
            });
            this.props.enqueueSnackbar('SMS message sent', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('SMS message failed', { variant: 'error' });
            this.setState({
                disableSendSmsButton: false,
            });
        });
    };

    updateSendSmsDataCheckbox = (name) => (event) => {
        const newState = event.target.checked ? 1 : 0;
        this.setState((state) => ({
            sendSmsData: {
                ...state.sendSmsData,
                [name]: newState,
            },
        }));
    };

    handleNoResponseRequired = () => {
        this.setState({
            disableNoResponseRequiredButton: true,
        });
        const leadCode = this.props.contact.leads[this.state.currentLeadTab].code;
        this.props.noResponseRequired(leadCode).then(() => {
            this.setState({
                disableNoResponseRequiredButton: false,
            });
            this.props.enqueueSnackbar('No Response Required Saved', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableNoResponseRequiredButton: false,
            });
            this.props.enqueueSnackbar('No Response Required failed', { variant: 'error' });
        });
    };

    handleCallbacktDateTimeChange = (chosenDate) => {
        this.setState((state) => ({
            callbackData: {
                ...state.callbackData,
                callbackDateTime: moment(chosenDate).format('YYYY-MM-DD HH:mm'),
            },
        }));
    };

    handleCancelCallback = () => {
        this.setState((state) => ({
            callbackData: {
                ...state.callbackData,
                callbackDateTime: null,
                disableCancelCallbackButton: true,
            },
        }));
    };

    render() {
        const {
            addLeadNote,
            addNewHistoryNote,
            // agreementStatuses,
            // agreementsAlerts,
            // assignStockItem,
            // branches,
            classes,
            contact,
            contactAgents,
            contactLeadsLoading,
            contactHistoriesLoading,
            contactLoading,
            // contactVisitsLoading,
            // emailContract,
            // engineers,
            errors,
            // exchangeAgreementItem,
            // fetchAgreementsData,
            // fetchSalesData,
            fetchHistoriesData,
            leadStatuses,
            // fetchLeadsSalesData,
            // paymentCards,
            // paymentCardsLoading,
            // productTypes,
            // scheduleDelivery,
            // customer,
            // paymentCards,
            // requestedLeadTab,
            // transactionStatusWaiting,
            user,
            userLoading,
            users,
            usersLoading,
        } = this.props;
        const {
            addLeadNoteData,
            callbackData,
            currentLeadTab,
            disableAddLeadNoteSaveButton,
            disableCancelCallbackButton,
            disableSendSelfMeasureInviteButton,
            disableAuthoriseLeadButton,
            disableDeclineLeadButton,
            disableEditLeadSubmitButton,
            disableCallbackSubmitButton,
            disableNoResponseRequiredButton,
            disableRegisterCallButton,
            disableSendLeadLinkButton,
            disableLeadToSlowTrackButton,
            disableSendSmsButton,
            editLeadData,
            sendSmsData,
            showCallbackDialog,
            showSendLeadLinkDialog,
            showLeadToSlowTrackDialog,
            showAddLeadNoteDialog,
            showSendSelfMeasureInviteDialog,
            showAuthoriseLeadDialog,
            showEditLeadDialog,
            showDeclineLeadDialog,
            showSendSmsDialog,
        } = this.state;

        let currentLead = {};
        if (get(contact, `leads[${currentLeadTab}]`)) {
            currentLead = contact.leads[currentLeadTab];
        }

        let currentContactHistories = null;
        if (!contactLoading && !contactHistoriesLoading) {
            currentContactHistories = contact.histories.filter((history) => history.entryType === 'LEAD' && history.entryTypeReferenceCode === `L${currentLead.code}`);
        }

        return (
            <>
                <Tile
                    tileTitle="Leads"
                    // refreshAction={fetchLeadsLeadsData}
                    refreshing={contactLeadsLoading}
                    tileMenuItems={[
                        {
                            action: () => this.handleLeadToSlowTrackClick(),
                            disabled: contactLeadsLoading || !currentLead.fastTrack,
                            label: 'Move Lead to Slow Track   ',
                            section: 1,
                        },
                        {
                            action: () => this.handleSendLeadLinkClick(),
                            disabled: contactLeadsLoading || currentLead.fastTrack,
                            label: 'Send Lead Link   ',
                            section: 1,
                        },
                        {
                            action: () => this.handleEditLeadClick(),
                            disabled:
                                contactLeadsLoading
                                || isEmpty(currentLead)
                                || currentLead.state === 'CONVERTED'
                                || currentLead.fastTrack,
                            label: 'Edit Lead   ',
                            section: 1,
                        },
                        {
                            action: () => this.handleCallbackClick(),
                            disabled:
                                contactLeadsLoading
                                || isEmpty(currentLead)
                                || currentLead.state === 'CONVERTED'
                                || currentLead.state === 'CANCELLED'
                                || currentLead.state === 'CLOSED'
                                || currentLead.fastTrack,
                            label: 'Set Callback   ',
                            section: 1,
                        },
                        {
                            action: () => this.handleSendSmsClick(),
                            disabled:
                                contactLeadsLoading
                                || isEmpty(currentLead)
                                || currentLead.state === 'CONVERTED'
                                || currentLead.state === 'CANCELLED'
                                || currentLead.state === 'CLOSED'
                                || currentLead.fastTrack,
                            label: 'Send Lead SMS  ',
                            section: 1,
                        },
                        {
                            action: () => this.handleSendSelfMeasureInviteClick(),
                            disabled:
                                contactLeadsLoading
                                || isEmpty(currentLead)
                                || currentLead.state === 'CONVERTED'
                                || currentLead.state === 'CANCELLED'
                                || currentLead.state === 'CLOSED'
                                || currentLead.fastTrack,
                            // || !currentLead.autoSMS1DateTime,
                            label: 'Send Self-Measure Invite  ',
                            section: 1,
                        },
                        // {
                        //     action: () => this.handleAddLeadNoteClick(),
                        //     disabled: true,
                        //     // contactLeadsLoading
                        //     // || isEmpty(currentLead),
                        //     label: 'Add Note  ',
                        //     section: 1,
                        // },
                        {
                            action: () => this.handleAuthoriseLeadClick(),
                            disabled:
                                contactLeadsLoading
                                || isEmpty(currentLead)
                                || currentLead.state !== 'REFERRED',
                            label: 'Authorise Lead  ',
                            section: 2,
                        },
                        {
                            action: () => this.handleDeclineLeadClick(),
                            disabled:
                                contactLeadsLoading
                                || isEmpty(currentLead)
                                || currentLead.state !== 'REFERRED',
                            label: 'Decline Lead  ',
                            section: 2,
                        },
                    ]}
                    scrollToMarker="leadsTop"
                >
                    { !contactLoading
                    && !contactLeadsLoading
                        ? (
                            <>
                                {contact.leads.length > 0
                                    ? (
                                        <>
                                            <AppBar
                                                position="static"
                                                className={classes.tabsAppBar}
                                            >
                                                <Tabs
                                                    className={classes.tabs}
                                                    value={currentLeadTab}
                                                    onChange={this.handleLeadChange}
                                                >
                                                    {contact.leads.map((lead) => (
                                                        <Tab
                                                            className={classes.tab}
                                                            key={lead.code}
                                                            label={
                                                                (
                                                                    <>
                                                                        <div>
                                                                            {`L${lead.code}`}
                                                                            {lead.agentFirstContactSmsResponseWaiting || lead.agentLastContactSmsResponseWaiting
                                                                                ? (<WarningIcon className={`${this.props.classes.hybridTextIconRight} ${this.props.classes.alert}`} />)
                                                                                : null}
                                                                        </div>
                                                                        <div>{`${lead.productType ? lead.productType : 'Unknown'}`}</div>
                                                                        <div className={classNames(
                                                                            {
                                                                                [classes.error]: lead.state === 'NEW' || lead.fastTrack,
                                                                            },
                                                                            {
                                                                                [classes.warning]: lead.state === 'OPEN',
                                                                            },
                                                                            {
                                                                                [classes.disabledText]: ['CLOSED', 'CANCELLED'].some((state) => lead.state === state),
                                                                            },
                                                                            {
                                                                                [classes.success]: ['CONVERTED'].some((state) => lead.state === state),
                                                                            },
                                                                        )}
                                                                        >
                                                                            {lead.fastTrack ? 'FAST TRACK' : titalise(lead.state || ' ')}
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        />
                                                    ))}
                                                </Tabs>
                                            </AppBar>
                                            <Paper className={classes.tabsPaper}>
                                                {/* <ContactSaleBadges
                                                    contact={contact}
                                                    contactLeadsLoading={contactLeadsLoading}
                                                    contactLoading={contactLoading}
                                                    currentLead={currentLead}
                                                        /> */}
                                                <Grid
                                                    container
                                                    spacing={0}
                                                >
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={currentLead.state === 'CANCELLED'}
                                                            green={currentLead.state === 'CONVERTED'}
                                                            label="Lead Status"
                                                            text={currentLead.fastTrack ? 'FAST TRACK' : titalise(currentLead.state || ' ')}
                                                            alert={currentLead.state === 'OPEN' || currentLead.fastTrack || currentLead.state === 'NEW'}
                                                            fieldStyle={currentLead.state === 'OPEN' ? {
                                                                style: 'alert',
                                                                tooltip: 'LEAD NOT CONVERTED',
                                                            } : false}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={
                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                            }
                                                            // green={currentLead.state === 'CONVERTED'}
                                                            label="Lead Temperature"
                                                            text={titalise(currentLead.leadTemperature || ' ')}
                                                            cold={currentLead.leadTemperature === 'COLD'}
                                                            alert={currentLead.leadTemperature === 'HOT'}
                                                            warning={currentLead.leadTemperature === 'WARM'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={
                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                            }
                                                            // green={currentLead.state === 'CONVERTED'}
                                                            label="Callback"
                                                            text={currentLead.callbackDateTime ? moment(currentLead.callbackDateTime).format('HH:mm DD/MM/YYYY') : null}
                                                            alert={currentLead.callbackDateTime ? moment(currentDateTime).isAfter(currentLead.callbackDateTime) : false}
                                                            warning={currentLead.callbackDateTime && moment(currentDateTime).isBefore(currentLead.callbackDateTime)
                                                                ? moment(currentDateTime).isSame(moment(currentLead.callbackDateTime), 'd')
                                                                : false}
                                                        />
                                                    </Grid>
                                                    {/* {console.log('>>> isAfter:', moment(currentDateTime).isAfter(currentLead.callbackDateTime))}
                                                    {console.log('>>> isBefore', moment(currentDateTime).isBefore(currentLead.callbackDateTime))}
                                                    {console.log('>>> isSameDay:', moment(currentDateTime).isSame(moment(currentLead.callbackDateTime), 'd'))} */}
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                >
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={
                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                            }
                                                            label="Created Time/Date"
                                                            text={moment(currentLead.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={
                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                            }
                                                            label="Created By"
                                                            text={currentLead.createdBy}
                                                            link={`/contact/${currentLead.createdByContactCode}`}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {currentLead.autoSMS1DateTime
                                                    ? (
                                                        <>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                            >
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        cancelled={
                                                                            currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                        }
                                                                        label="First Contact SMS"
                                                                        text={currentLead.firstInboundSmsDateTime ? moment(currentLead.firstInboundSmsDateTime).format('H:mm DD/MM/YYYY') : null}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        cancelled={
                                                                            currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                        }
                                                                        label="Last Contact SMS"
                                                                        text={currentLead.lastInboundSmsDateTime ? moment(currentLead.lastInboundSmsDateTime).format('H:mm DD/MM/YYYY') : null}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                            >
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        cancelled={
                                                                            currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                        }
                                                                        label="First Agent SMS"
                                                                        text={currentLead.firstAgentSmsContactDateTime ? moment(currentLead.firstAgentSmsContactDateTime).format('H:mm DD/MM/YYYY') : null}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        cancelled={
                                                                            currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                        }
                                                                        label="First Agent Call"
                                                                        text={currentLead.firstAgentCallContactDateTime ? moment(currentLead.firstAgentCallContactDateTime).format('H:mm DD/MM/YYYY') : null}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        cancelled={
                                                                            currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                        }
                                                                        label="Last Agent Communication"
                                                                        text={currentLead.lastAgentContactDateTime ? moment(currentLead.lastAgentContactDateTime).format('H:mm DD/MM/YYYY') : null}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                container
                                                                spacing={0}
                                                            >
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        cancelled={
                                                                            currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                        }
                                                                        label="Agent Initial Response Time"
                                                                        // text={currentLead.firstInboundSmsDateTime
                                                                        //     ? `${moment(currentLead.firstAgentContactDateTime ? currentLead.firstAgentContactDateTime : currentDateTime).diff(currentLead.firstInboundSmsDateTime, 'minutes')}${currentLead.firstAgentContactDateTime ? 'mins (responded)' : 'mins (still waiting)'}`
                                                                        //     : 'No response from contact'}
                                                                        text={currentLead.firstInboundSmsDateTime
                                                                            ? `${currentLead.agentFirstContactSmsResponseTime}mins (${currentLead.agentFirstContactSmsResponseWaiting ? 'waiting' : 'replied'})`
                                                                            : 'No response from contact'}
                                                                        alert={currentLead.agentFirstContactSmsResponseWaiting}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField
                                                                        cancelled={
                                                                            currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                        }
                                                                        label="Contact Waiting From Last SMS"
                                                                        text={currentLead.lastInboundSmsDateTime
                                                                            ? `${currentLead.agentLastContactSmsResponseTime}mins (${currentLead.agentLastContactSmsResponseWaiting ? 'waiting' : 'replied'})`
                                                                            : 'No response from contact'}
                                                                        alert={currentLead.agentLastContactSmsResponseWaiting}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            {currentLead.agentLastContactSmsResponseWaiting || currentLead.agentFirstContactSmsResponseWaiting
                                                                ? (
                                                                    <Grid
                                                                        container
                                                                        spacing={0}
                                                                    >
                                                                        <Grid item xs={4}>
                                                                            <HybridTextField
                                                                                cancelled={
                                                                                    currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                                }
                                                                                label="SMS Awaiting Response"
                                                                                text={currentLead.lastContactSmsMessage}
                                                                                alert
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={4} className={classes.creditScoreContainer}>
                                                                            <div className={classes.textButtonContainer}>
                                                                                <>
                                                                                    <Button
                                                                                        color="default"
                                                                                        className={classes.textButton}
                                                                                        onClick={this.handleNoResponseRequired}
                                                                                        variant="outlined"
                                                                                        disabled={disableNoResponseRequiredButton}
                                                                                    >
                                                                                        NO RESPONSE REQUIRED
                                                                                    </Button>
                                                                                </>
                                                                                <>
                                                                                    <Button
                                                                                        color="default"
                                                                                        className={classes.textButton}
                                                                                        onClick={this.handleRegisterCall}
                                                                                        variant="outlined"
                                                                                        disabled={disableRegisterCallButton}
                                                                                    >
                                                                                        REGISTER PHONE CALL
                                                                                    </Button>
                                                                                </>
                                                                            </div>
                                                                        </Grid>
                                                                    </Grid>
                                                                ) : null}
                                                        </>
                                                    ) : null }
                                                <Grid
                                                    container
                                                    spacing={0}
                                                >
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={
                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                            }
                                                            label="Self-Measure Invite Sent Time/Date"
                                                            text={currentLead.selfMeasureInviteSentDateTime ? moment(currentLead.selfMeasureInviteSentDateTime).format('H:mm DD/MM/YYYY') : ' '}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                >
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={
                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                            }
                                                            label="Scheduled Appointment Time/Date"
                                                            text={currentLead.scheduledAppointmentDateTime ? moment(currentLead.scheduledAppointmentDateTime).format('H:mm DD/MM/YYYY') : ' '}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={
                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                            }
                                                            label="Scheduled Appointment Agent"
                                                            text={currentLead.scheduledAppointmentContact}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    spacing={0}
                                                >
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={
                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                            }
                                                            label="Credit Check Reference"
                                                            text={(currentLead.state === 'REFERRED' || currentLead.state === 'DECLINED') ? currentLead.referralReference : 'n/a'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <HybridTextField
                                                            cancelled={
                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                            }
                                                            label="Credit Check Reason"
                                                            text={(currentLead.state === 'REFERRED' || currentLead.state === 'DECLINED') ? currentLead.referralReason : 'n/a'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                {(currentLead.notes || []).length > 0
                                                    ? (
                                                        <Grid
                                                            container
                                                            spacing={0}
                                                        >
                                                            <div className={classes.textFieldLabel}>
                                                                Lead Notes
                                                            </div>
                                                            {sortBy(currentLead.notes, 'createdDateTime').map((note, i) => (
                                                                <Grid
                                                                    key={note.code}
                                                                    container
                                                                    spacing={0}
                                                                    className={classes.leadNoteContainer}
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <HybridTextField
                                                                            cancelled={
                                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                            }
                                                                            label="Note"
                                                                            text={note.note}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <HybridTextField
                                                                            cancelled={
                                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                            }
                                                                            label="Created By"
                                                                            text={note.createdBy}
                                                                            link={`/contact/${note.createdByContactCode}`}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <HybridTextField
                                                                            cancelled={
                                                                                currentLead.state === 'CANCELLED'
                                                                || currentLead.state === 'CLOSED'
                                                                || currentLead.fastTrack
                                                                            }
                                                                            label="Created Time/Date"
                                                                            text={moment(note.createdDateTime).format('HH:mm DD/MM/YYYY')}
                                                                        />
                                                                    </Grid>
                                                                </Grid>

                                                            ))}
                                                        </Grid>
                                                    ) : null}

                                                <Dialog
                                                    open={showAddLeadNoteDialog}
                                                    onClose={this.handleAddLeadNoteCloseDialogClick}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogContent className={classes.dialogContent}>
                                                        <div className={classes.dialogContentContainer}>
                                                            <>
                                                                <Typography variant="h6" gutterBottom>
                                                                    New Lead Note
                                                                </Typography>
                                                                <DialogContentText>
                                                                    Enter the note text below and click [SAVE].
                                                                    The current time, date, and your name will be
                                                                    recorded automatically.
                                                                </DialogContentText>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            onChange={this.updateAddLeadNoteData}
                                                                            className={classes.formControl}
                                                                            value={addLeadNoteData.note}
                                                                            name="note"
                                                                            id="note"
                                                                            label="Note"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            multiline
                                                                            rows="6"
                                                                            // errors={!!errors.note}
                                                                            // helperText={errors.note}
                                                                            autoFocus
                                                                        />
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            disabled={disableAddLeadNoteSaveButton || !addLeadNoteData.note}
                                                                            onClick={this.handleAddLeadNote}
                                                                        >
                                                                            SAVE
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className={classes.dialogActions}>
                                                        <Button
                                                            onClick={this.handleAddLeadNoteCloseDialogClick}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={showAuthoriseLeadDialog}
                                                    onClose={this.handleAuthoriseLeadCloseDialogClick}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                    className={classes.newEngineerDialog}
                                                >
                                                    <DialogContent className={classes.dialogContent}>
                                                        <div className={classes.dialogContentContainer}>
                                                            <>
                                                                <Typography
                                                                    variant="h6"
                                                                    gutterBottom
                                                                    className={classes.dialogTitle}
                                                                >
                                                                    Authorise Lead
                                                                </Typography>
                                                                <DialogContentText className={classNames(classes.dialogText, classes.bumpBottom3)}>
                                                                    This lead has been automatically 'Referred' due to the contact failing their credit check.
                                                                </DialogContentText>
                                                                <DialogContentText className={classNames(classes.dialogText, classes.bumpBottom3)}>
                                                                    Are you sure you want to authorise this lead?
                                                                </DialogContentText>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleAuthoriseLead}
                                                                            disabled={disableAuthoriseLeadButton}
                                                                        >
                                                                            AUTHORISE
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className={classes.dialogActions}>
                                                        <Button
                                                            onClick={this.handleAuthoriseLeadCloseDialogClick}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={showDeclineLeadDialog}
                                                    onClose={this.handleDeclineLeadCloseDialogClick}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                    className={classes.newEngineerDialog}
                                                >
                                                    <DialogContent className={classes.dialogContent}>
                                                        <div className={classes.dialogContentContainer}>
                                                            <>
                                                                <Typography
                                                                    variant="h6"
                                                                    gutterBottom
                                                                    className={classes.dialogTitle}
                                                                >
                                                                    Decline Lead
                                                                </Typography>
                                                                <DialogContentText className={classNames(classes.dialogText, classes.bumpBottom3)}>
                                                                    This lead has been automatically 'Referred' due to the contact failing their credit check.
                                                                </DialogContentText>
                                                                <DialogContentText className={classNames(classes.dialogText, classes.bumpBottom3)}>
                                                                    Are you sure you want to decline this lead?
                                                                </DialogContentText>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleDeclineLead}
                                                                            disabled={disableDeclineLeadButton}
                                                                        >
                                                                            DECLINE
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className={classes.dialogActions}>
                                                        <Button
                                                            onClick={this.handleDeclineLeadCloseDialogClick}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={showEditLeadDialog}
                                                    onClose={this.handleCloseEditLeadDialogClick}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogContent className={classes.dialogContent}>
                                                        <div className={classes.dialogContentContainer}>
                                                            <>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Edit Lead
                                                                </Typography>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <FormControl
                                                                            className={classes.menuFormControl}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                        >
                                                                            <InputLabel htmlFor="assignedToContactCode" className={classes.inputLabel}>Assigned To</InputLabel>
                                                                            <Select
                                                                                keyboard="true"
                                                                                input={(
                                                                                    <OutlinedInput
                                                                                        onChange={this.updateEditLeadData}
                                                                                        name="assignedToContactCode"
                                                                                        id="assignedToContactCode"
                                                                                    />
                                                                                )}
                                                                                value={editLeadData.assignedToContactCode || 'null'}
                                                                            >
                                                                                <MenuItem key="null" value="null"><em>Automatic</em></MenuItem>
                                                                                {contactAgents.map((agent) => (
                                                                                    [
                                                                                        <MenuItem
                                                                                            key={agent.code}
                                                                                            value={agent.code}
                                                                                        >
                                                                                            {`${agent.firstname} ${agent.surname}`}
                                                                                        </MenuItem>,
                                                                                    ]
                                                                                ))}
                                                                            </Select>
                                                                            {errors.assignedToContactCode && (
                                                                                <FormHelperText>{errors.assignedToContactCode}</FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControl
                                                                            className={classes.menuFormControl}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                        >
                                                                            <InputLabel htmlFor="status" className={classes.inputLabel}>Lead Status</InputLabel>
                                                                            <Select
                                                                                keyboard="true"
                                                                                input={(
                                                                                    <OutlinedInput
                                                                                        onChange={this.updateEditLeadData}
                                                                                        name="state"
                                                                                        id="state"
                                                                                    />
                                                                                )}
                                                                                value={editLeadData.state || ''}
                                                                            >
                                                                                {leadStatuses.map((state) => (
                                                                                    [
                                                                                        <MenuItem
                                                                                            key={state}
                                                                                            value={state}
                                                                                            disabled={state === currentLead.state
                                                                                            || ['CONVERTED', 'CONVERTED', 'DECLINED', 'DENIED', 'REFERRED'].some((menuState) => menuState === state)}
                                                                                        >
                                                                                            {capitalisedSnakeCaseToTitleCase(state)}
                                                                                        </MenuItem>,
                                                                                    ]
                                                                                ))}
                                                                            </Select>
                                                                            {errors.leadState && (
                                                                                <FormHelperText>{errors.leadState}</FormHelperText>
                                                                            )}
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControl component="fieldset" className={classes.formControl}>
                                                                            <FormLabel component="legend">Lead Temperature</FormLabel>
                                                                            <RadioGroup
                                                                                aria-label="lead temperature"
                                                                                name="leadTemperature"
                                                                                value={editLeadData.leadTemperature}
                                                                                onChange={this.updateEditLeadData}
                                                                                className={classes.radioButtons}
                                                                            >
                                                                                <FormControlLabel
                                                                                    value="COLD"
                                                                                    control={<Radio classes={{ root: classes.radioButtonCold, checked: classes.checked }} />}
                                                                                    label="Cold"
                                                                                    labelPlacement="end"
                                                                                />
                                                                                <FormControlLabel
                                                                                    value="WARM"
                                                                                    control={<Radio classes={{ root: classes.radioButtonWarm, checked: classes.checked }} />}
                                                                                    label="Warm"
                                                                                    labelPlacement="end"
                                                                                />
                                                                                <FormControlLabel
                                                                                    // eslint-disable-next-line react/jsx-boolean-value
                                                                                    value="HOT"
                                                                                    control={<Radio classes={{ root: classes.radioButtonHot, checked: classes.checked }} />}
                                                                                    label="Hot"
                                                                                    labelPlacement="end"
                                                                                />
                                                                            </RadioGroup>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            disabled={disableEditLeadSubmitButton || isEmpty(currentLead) || isEmpty(editLeadData)}
                                                                            onClick={this.handleEditLead}
                                                                        >
                                                                            SAVE
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className={classes.dialogActions}>
                                                        <Button
                                                            onClick={this.handleCloseEditLeadDialogClick}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={showSendSmsDialog}
                                                    onClose={this.handleCloseSendSmsDialogClick}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogContent className={classes.dialogContent}>
                                                        <div className={classes.dialogContentContainer}>
                                                            <>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Send Lead SMS Message
                                                                </Typography>
                                                                <DialogContentText>
                                                                    Use only for sending SMS regarding this Lead!
                                                                </DialogContentText>
                                                                <DialogContentText>
                                                                    {sendSmsData.sendAsAgent
                                                                        ? (
                                                                            <>
                                                                                Sending as agent will allow response from customer.
                                                                            </>
                                                                        )
                                                                        : (
                                                                            <>
                                                                                Message will automatically start "PAY WEEKLY:" and end ". Do not reply"
                                                                            </>
                                                                        )}
                                                                </DialogContentText>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            onChange={this.updateSendSmsData}
                                                                            className={classes.formControl}
                                                                            name="content"
                                                                            id="content"
                                                                            label="SMS Message"
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            multiline
                                                                            rows="6"
                                                                            inputRef={(input) => {
                                                                                if (input != null) {
                                                                                    input.focus();
                                                                                }
                                                                            }}
                                                                            /* {error={!!errors.rentalAgreementCode}} */
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <FormControlLabel
                                                                            control={(
                                                                                <Checkbox
                                                                                    name="sendAsAgent"
                                                                                    id="sendAsAgent"
                                                                                    checked={sendSmsData.sendAsAgent === 1}
                                                                                    onChange={this.updateSendSmsDataCheckbox('sendAsAgent')}
                                                                                    value={sendSmsData.sendAsAgent}
                                                                                />
                                                                            )}
                                                                            label="Send as Agent"
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleSendSms}
                                                                            disabled={disableSendSmsButton}
                                                                        >
                                                                            SEND
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className={classes.dialogActions}>
                                                        <Button
                                                            onClick={this.handleCloseSendSmsDialogClick}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={showSendSelfMeasureInviteDialog}
                                                    onClose={this.handleSendSelfMeasureInviteCloseDialogClick}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogContent className={classes.dialogContent}>
                                                        <div className={classes.dialogContentContainer}>
                                                            <>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Send Self-Measure Invite
                                                                </Typography>
                                                                <DialogContentText>
                                                                    Only use this option where the contact will select the flooring and measure the required rooms themselves.
                                                                </DialogContentText>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleSendSelfMeasureInvite}
                                                                            disabled={disableSendSelfMeasureInviteButton}
                                                                        >
                                                                            SEND
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className={classes.dialogActions}>
                                                        <Button
                                                            onClick={this.handleSendSelfMeasureInviteCloseDialogClick}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={showSendLeadLinkDialog}
                                                    onClose={this.handleSendLeadLinkCloseDialogClick}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogContent className={classes.dialogContent}>
                                                        <div className={classes.dialogContentContainer}>
                                                            <>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Send Lead Link
                                                                </Typography>
                                                                <DialogContentText>
                                                                    This option will SMS the contact, using their mobile number on record, a link to the enquiry form on the Pay Weekly website.
                                                                </DialogContentText>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleSendLeadLink}
                                                                            disabled={disableSendSelfMeasureInviteButton}
                                                                        >
                                                                            SEND
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className={classes.dialogActions}>
                                                        <Button
                                                            onClick={this.handleSendLeadLinkCloseDialogClick}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={showLeadToSlowTrackDialog}
                                                    onClose={this.handleLeadToSlowTrackCloseDialogClick}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogContent className={classes.dialogContent}>
                                                        <div className={classes.dialogContentContainer}>
                                                            <>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Move Lead to Slow Track
                                                                </Typography>
                                                                <DialogContentText>
                                                                    This option will unlock Fast Track lead for manual Slow Track processing.
                                                                </DialogContentText>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            onClick={this.handleLeadToSlowTrack}
                                                                            disabled={disableLeadToSlowTrackButton}
                                                                        >
                                                                            SLOW TRACK
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className={classes.dialogActions}>
                                                        <Button
                                                            onClick={this.handleLeadToSlowTrackCloseDialogClick}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                                <Dialog
                                                    open={showCallbackDialog}
                                                    onClose={this.handleCloseCallbackDialogClick}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                >
                                                    <DialogContent className={classes.dialogContent}>
                                                        <div className={classes.dialogContentContainer}>
                                                            <>
                                                                <Typography variant="h6" gutterBottom>
                                                                    Callback
                                                                </Typography>
                                                                <Grid container spacing={0}>
                                                                    <Grid item xs={12}>
                                                                        <DateTimePicker
                                                                            name="retryPaymentDateTime"
                                                                            id="retryPaymentDateTime"
                                                                            margin="normal"
                                                                            label="Retry Payment Date/Time"
                                                                            value={callbackData.callbackDateTime}
                                                                            onChange={this.handleCallbacktDateTimeChange}
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            format="DD/MM/YYYY HH:mm"
                                                                            showTodayButton
                                                                            minDate={new Date(moment(currentDate).format('YYYY-MM-DD HH:mm'))}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            disabled={disableCancelCallbackButton || !currentLead.callbackDateTime}
                                                                            onClick={this.handleCancelCallback}
                                                                        >
                                                                            CANCEL CALLBACK
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid
                                                                    container
                                                                    spacing={0}
                                                                    direction="column"
                                                                    alignItems="center"
                                                                    justify="center"
                                                                >
                                                                    <Grid item xs={12}>
                                                                        <Button
                                                                            className={classes.dialogButton}
                                                                            disabled={disableCallbackSubmitButton}
                                                                            onClick={this.handleCallback}
                                                                        >
                                                                            SAVE
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        </div>
                                                    </DialogContent>
                                                    <DialogActions className={classes.dialogActions}>
                                                        <Button
                                                            onClick={this.handleCloseCallbackDialogClick}
                                                            color="primary"
                                                            autoFocus
                                                        >
                                                            Close
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>

                                            </Paper>
                                        </>
                                    )
                                    : <div className={classes.emptyContent}>No leads found.</div>}
                            </>
                        )
                        : (
                            <div className={classes.progressContainer}>
                                <CircularProgress className={classes.progress} variant="indeterminate" />
                            </div>
                        )}
                </Tile>
                <ContactHistory
                    addNewHistoryNote={addNewHistoryNote}
                    contact={contact}
                    contactHistoriesLoading={contactHistoriesLoading}
                    entryType="LEAD"
                    entryTypeReferenceCode={isEmpty(currentLead) ? '' : `L${currentLead.code}`}
                    fetchHistoriesData={fetchHistoriesData}
                    histories={currentContactHistories}
                    tileTitlePostfix={isEmpty(currentLead) ? '' : ` (L${currentLead.code})`}
                    errors={errors}
                />
            </>
        );
    }
}

export default withSnackbar(withStyles(styles)(ContactLeads));
